<template>
  <v-container>
    <!-- partners -->

    <v-layout>
      <v-flex display-4 font-weight-light my-5 pt-5> .partner </v-flex>
    </v-layout>

    <v-container grid-list-lg pa-0 mx-0 my-5>
      <v-layout row wrap>
        <v-flex v-for="(partner, i) in partners" :key="i" md3 sm4 xs6>
          <v-card flat tile style="padding: 20px" color="white">
            <a :href="partner.link" target="_blank">
              <div
                class="partner-container big"
                :style="
                  'background-image:url(https://sjr-bucket.s3.eu-central-1.amazonaws.com/' +
                  partner.pic.name
                "
              ></div>
            </a>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["partners"],
  mounted: function () {
    this.setBgImage(this.$route.name);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
