import axios from "axios";
import moment from "moment";

const Globals = {
  install(Vue) {
    Vue.prototype.scrollToElement = function (elementId) {
      this.drawer = false;
      setTimeout(() => {
        this.$scrollTo("#" + elementId, 1000, { offset: -120 });
      }, 1000);
    };
    Vue.prototype.setBgImage = function (pathName) {
      const img = "url('/bgimg/" + pathName + ".jpg')";
      document.querySelector(".bg").style.backgroundImage = img;
    };
    Vue.prototype.getFbEvents = function () {
      const promise = new Promise((resolve, reject) => {
        axios
          .get(
            "https://fb.widerstand.digital/pop/342428552200/events?fields=cover,id,description,name,start_time,place&limit=1000&time_filter=upcoming"
          )
          .then((response) => {
            for (var fbEvent of response.data.data) {
              fbEvent.start_time = moment(fbEvent.start_time).valueOf();
            }
            resolve(response.data.data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    };
    Vue.prototype.sendMail = function (content) {
      const promise = new Promise((resolve, reject) => {
        axios
          .post("/mail/mail.php", content)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    };
    Vue.prototype.sendFeedback = function (content) {
      const promise = new Promise((resolve, reject) => {
        axios
          .post("/mail/feedback.php", content)
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
      return promise;
    };
  },
};

export default Globals;
