<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .termine </v-flex>
    </v-layout>

    <!-- events -->

    <v-layout column>
      <template v-if="events.length > 0">
        <v-flex
          :id="'e' + event._id"
          v-for="(event, i) in events"
          :key="i"
          xs12
          my-5
        >
          <v-card>
            <v-carousel
              class="flat-carousel"
              height="auto"
              v-if="event.pics.length > 0"
              cycle
              :hide-delimiter-background="event.pics.length === 1"
              :hide-delimiters="event.pics.length === 1"
              :hide-controls="event.pics.length === 1"
            >
              <v-carousel-item v-for="(pic, i) in event.pics" :key="i">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="
                    'https://sjr-bucket.s3.eu-central-1.amazonaws.com/' +
                    pic.name
                  "
                >
                  <v-avatar
                    v-if="event.internal"
                    color="warning"
                    class="rotate ml-3 mt-3 pa-3 white--text caption"
                    size="60"
                  >
                    INTERN
                  </v-avatar>
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <v-card-title
              :class="{
                'display-1': true,
                'font-weight-light': true,
                'mt-4': true,
                'px-0': $vuetify.breakpoint.xsAndDown,
                'px-5': $vuetify.breakpoint.smAndUp,
              }"
              >{{ event.title }}</v-card-title
            >
            <div
              :class="{
                headline: true,
                'font-weight-light': true,
                'mt-4': true,
                'px-0': $vuetify.breakpoint.xsAndDown,
                'px-5': $vuetify.breakpoint.smAndUp,
              }"
            >
              {{ event.subTitle }}
            </div>
            <v-card-text
              :class="{
                'body-1': true,
                'px-0': $vuetify.breakpoint.xsAndDown,
                'px-5': $vuetify.breakpoint.smAndUp,
              }"
            >
              <div class="font-weight-light font-italic">
                Wann:
                <template
                  v-if="$moment(event.date.start).isSame(event.date.end, 'day')"
                >
                  <span>
                    {{ $moment(event.date.start).format("dddd") }} der
                  </span>
                  <span
                    >{{ $moment(event.date.start).format("DD.MM.YY") }},
                  </span>
                  <span>
                    {{ $moment(event.time.start).format("HH:mm") }} -
                    {{ $moment(event.time.end).format("HH:mm") }}
                    Uhr</span
                  >
                </template>
                <template v-else>
                  <span>
                    <span
                      >{{ $moment(event.date.start).format("dddd") }} der
                    </span>
                    {{ $moment(event.date.start).format("DD.MM.YY") }}
                    {{ $moment(event.time.start).format("HH:mm") }} Uhr
                    <span class="card-text regular">bis </span>
                  </span>
                  <span>
                    <span>
                      {{ $moment(event.date.end).format("dddd") }} den
                    </span>
                    {{ $moment(event.date.end).format("DD.MM.YY") }}
                    {{ $moment(event.time.end).format("HH:mm") }}
                    Uhr
                  </span>
                </template>
              </div>
              <div class="font-weight-light font-italic">
                Wo: {{ event.location ? event.location : "tba" }}
              </div>
              <br />
              <p v-html="event.info"></p>
            </v-card-text>

            <v-card-actions class="px-5 pb-5">
              <v-btn
                block
                color="primary"
                :href="'https://sjr-ulm.de/veranstaltungen/' + event._id"
                target="_blank"
              >
                <span class="black--text">
                  Weitere Details / Anmeldung gibt es HIER
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </template>
      <template v-else>
        <div class="display-1 font-weight-light">
          Aktuell keine Termine eingetragen.
        </div>
      </template>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["events"],
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
};
</script>

<style scoped>
.fb-text {
  white-space: pre-wrap;
}
</style>
