var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"display-4":"","font-weight-thin":"","my-5":"","pt-5":""}},[_vm._v(" .termine ")])],1),_c('v-layout',{attrs:{"column":""}},[(_vm.events.length > 0)?_vm._l((_vm.events),function(event,i){return _c('v-flex',{key:i,attrs:{"id":'e' + event._id,"xs12":"","my-5":""}},[_c('v-card',[(event.pics.length > 0)?_c('v-carousel',{staticClass:"flat-carousel",attrs:{"height":"auto","cycle":"","hide-delimiter-background":event.pics.length === 1,"hide-delimiters":event.pics.length === 1,"hide-controls":event.pics.length === 1}},_vm._l((event.pics),function(pic,i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":'https://sjr-bucket.s3.eu-central-1.amazonaws.com/' +
                  pic.name}},[(event.internal)?_c('v-avatar',{staticClass:"rotate ml-3 mt-3 pa-3 white--text caption",attrs:{"color":"warning","size":"60"}},[_vm._v(" INTERN ")]):_vm._e()],1)],1)}),1):_vm._e(),_c('v-card-title',{class:{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': _vm.$vuetify.breakpoint.xsAndDown,
              'px-5': _vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(_vm._s(event.title))]),_c('div',{class:{
              headline: true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': _vm.$vuetify.breakpoint.xsAndDown,
              'px-5': _vm.$vuetify.breakpoint.smAndUp,
            }},[_vm._v(" "+_vm._s(event.subTitle)+" ")]),_c('v-card-text',{class:{
              'body-1': true,
              'px-0': _vm.$vuetify.breakpoint.xsAndDown,
              'px-5': _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('div',{staticClass:"font-weight-light font-italic"},[_vm._v(" Wann: "),(_vm.$moment(event.date.start).isSame(event.date.end, 'day'))?[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.date.start).format("dddd"))+" der ")]),_c('span',[_vm._v(_vm._s(_vm.$moment(event.date.start).format("DD.MM.YY"))+", ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.time.start).format("HH:mm"))+" - "+_vm._s(_vm.$moment(event.time.end).format("HH:mm"))+" Uhr")])]:[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$moment(event.date.start).format("dddd"))+" der ")]),_vm._v(" "+_vm._s(_vm.$moment(event.date.start).format("DD.MM.YY"))+" "+_vm._s(_vm.$moment(event.time.start).format("HH:mm"))+" Uhr "),_c('span',{staticClass:"card-text regular"},[_vm._v("bis ")])]),_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.date.end).format("dddd"))+" den ")]),_vm._v(" "+_vm._s(_vm.$moment(event.date.end).format("DD.MM.YY"))+" "+_vm._s(_vm.$moment(event.time.end).format("HH:mm"))+" Uhr ")])]],2),_c('div',{staticClass:"font-weight-light font-italic"},[_vm._v(" Wo: "+_vm._s(event.location ? event.location : "tba")+" ")]),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s(event.info)}})]),_c('v-card-actions',{staticClass:"px-5 pb-5"},[_c('v-btn',{attrs:{"block":"","color":"primary","href":'https://sjr-ulm.de/veranstaltungen/' + event._id,"target":"_blank"}},[_c('span',{staticClass:"black--text"},[_vm._v(" Weitere Details / Anmeldung gibt es HIER ")])])],1)],1)],1)}):[_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(" Aktuell keine Termine eingetragen. ")])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }