<template>
  <v-app v-if="showPage" class="app" dark>
    <template v-if="ie">
      <div
        style="
          display: inline;
          position: relative;
          transform: translateX(-50%) translateY(-50%);
          left: 50%;
          max-width: 500px;
          top: 50%;
        "
      >
        <div class="display-1">
          <img
            width="200px"
            style="margin-left: -30px"
            src="../static/header_logo_w.png"
          /><br />
          Bitte nutze einen aktuellen Browser. Internet Explorer wird von dieser
          Seite nicht unterstützt. Vielen Dank!
        </div>
      </div>
    </template>
    <template v-else>
      <span style="background-image: url('/bgimg/home.jpg')"></span>
      <span style="background-image: url('/bgimg/neues.jpg')"></span>
      <span style="background-image: url('/bgimg/projekte.jpg')"></span>
      <span style="background-image: url('/bgimg/kontakt.jpg')"></span>
      <span style="background-image: url('/bgimg/partner.jpg')"></span>
      <span style="background-image: url('/bgimg/angebote.jpg')"></span>
      <span style="background-image: url('/bgimg/ueber-uns.jpg')"></span>
      <span style="background-image: url('/bgimg/termine.jpg')"></span>
      <div class="bg"></div>

      <v-toolbar app flat height="100px" class="main-toolbar">
        <div class="header-logo clickable" @click="$router.push('/')">
          <img src="../static/header_logo_w.png" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          @click="$router.push('/neues')"
          class="responsive-large main-btn"
          flat
        >
          .neues
        </v-btn>
        <v-btn
          @click="$router.push('/projekte')"
          class="responsive-large main-btn"
          flat
        >
          .was wir machen
        </v-btn>
        <v-btn
          @click="$router.push('/termine')"
          class="responsive-large main-btn"
          flat
        >
          .termine
        </v-btn>
        <v-btn
          @click="$router.push('/ueber-uns')"
          class="responsive-large main-btn"
          flat
        >
          .wer wir sind
        </v-btn>
        <v-btn
          @click="$router.push('/partner')"
          class="responsive-large main-btn"
          flat
          large
        >
          .partner
        </v-btn>
        <v-btn
          @click="$router.push('/kontakt')"
          class="responsive-large main-btn"
          flat
        >
          .kontakt
        </v-btn>

        <v-btn icon @click.stop="drawer = !drawer" class="responsive-small">
          <v-icon>menu</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="toolbar-odd">
        <svg
          width="100%"
          height="60px"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="0,0 100,0 100,100" class="triangle" />
        </svg>
      </div>

      <v-content class="content">
        <router-view
          :events="events"
          :projects="projects"
          :news="news"
          :partners="partners"
          :playlist="playlist"
          :team="team"
          :links="links"
          :organisation="organisation"
          :microsite="microsite"
          :sjr="sjr"
          :sjrMicrosite="sjrMicrosite"
        />
      </v-content>

      <v-navigation-drawer
        class="responsive-small"
        temporary
        v-model="drawer"
        fixed
        app
        right
      >
        <v-list>
          <v-list-tile @click="drawer = !drawer">
            <v-list-tile-action>
              <v-icon>close</v-icon>
            </v-list-tile-action>
            <v-list-tile-title></v-list-tile-title>
          </v-list-tile>
          <v-list-tile> </v-list-tile>
          <v-list-tile @click="$router.push('/neues')">
            <v-list-tile-title> .neues </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push('/projekte')">
            <v-list-tile-title> .was wir machen </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push('/termine')">
            <v-list-tile-title> .termine </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push('/ueber-uns')">
            <v-list-tile-title> .wer wir sind </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push('/partner')">
            <v-list-tile-title> .partner </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$router.push('/kontakt')">
            <v-list-tile-title> .kontakt </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>

      <div class="odd-footer">
        <svg
          width="100%"
          height="60px"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="0,0 100,100 0,100" class="triangle" />
        </svg>
      </div>

      <v-layout v-bind="binding" class="pa-3 footer" height="50px">
        <v-flex text-xs-center text-md-left>
          <a style="text-decoration: none" href="mailto:info@popbastion.de">
            <v-btn icon>
              <v-icon>mail</v-icon>
            </v-btn>
          </a>
          <a target="_blank" href="https://www.facebook.com/popbastion/">
            <v-btn icon>
              <img height="20px" src="../static/facebook_w.png" />
            </v-btn>
          </a>
          <a
            target="_blank"
            href="https://open.spotify.com/user/popbastion.ulm?si=L_UfD6vxTcO7OpBNzWStkw"
          >
            <v-btn icon>
              <img height="20px" src="../static/spotify_w.png" />
            </v-btn>
          </a>
          <a target="_blank" href="https://www.instagram.com/popbastion.ulm/">
            <v-btn icon>
              <img height="20px" src="../static/instagram_w.png" />
            </v-btn>
          </a>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex pt-3 text-xs-center text-md-right>
          <div @click="$router.push('/kontakt/kontakt')" class="footer-item">
            Kontakt
          </div>
          <div @click="$router.push('/kontakt/impressum')" class="footer-item">
            Impressum
          </div>
          |
          <div
            class="footer-item"
            @click="$router.push('/kontakt/datenschutz')"
          >
            Datenschutz
          </div>
          |
          <a style="color: #ffffff" target="_blank" href="https://sjr-ulm.de">
            <div class="footer-item">
              &copy; {{ new Date().getFullYear() }} Stadtjugendring Ulm e.V.
            </div>
          </a>
        </v-flex>
      </v-layout>
      <v-layout row style="z-index: 1" text-xs-center>
        <v-flex style="background-color: #212121" xs12>
          <vue-mailchimp-email-signup-form
            :element-id="'first-email-signup-form'"
            :url="'https://popbastion.us14.list-manage.com/subscribe/post?u=8d2a9257a5382169bc5e3b48c&amp;id=8baf2fcd9f'"
            :title="'Ihr wollt auf dem Laufenden gehalten werden?'"
            :subtitle="'Dann meldet euch doch für unseren Newsletter hier an.'"
          />
        </v-flex>
      </v-layout>
    </template>
  </v-app>
</template>
<script>
import "vue-mailchimp-email-signup-form/dist/vue-mailchimp-email-signup-form.css";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      drawer: false,
      links: [],
      organisation: {},
      microsite: {},
      partners: [],
      news: [],
      events: [],
      projects: [],
      team: [],
      sjr: {},
      sjrMicrosite: {},
      playlist: "",
      ie: false,
      showPage: false,
    };
  },
  async mounted() {
    if (
      navigator.appName == "Microsoft Internet Explorer" ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      )
    ) {
      this.ie = true;
    }
    // etage = 5e71ef39a9fda91dbbe6f35c 5e79d475e7c84c19a193bae3
    // popbaston = 5e71edfea9fda91dbbe6f35b
    const yesterday = this.$moment().subtract(1, "days").toISOString();
    this.events = (
      await axios.get(
        `https://sjr.widerstand.digital/events?organisation=5e71edfea9fda91dbbe6f35b&$or[0][date.end][$gte]=${yesterday}&$or[1][interval.end][$gte]=${yesterday}`
      )
    ).data.sort((a, b) => {
      return new Date(b.date.start) - new Date(b.date.start);
    });
    this.news = (
      await axios.get(
        `https://sjr.widerstand.digital/news?organisation=5e71edfea9fda91dbbe6f35b&$or[0][internal][$ne]=true&$or[1][internal]=false&$sort[updatedAt]=-1`
      )
    ).data.data.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(b.updatedAt);
    });

    this.organisation = (
      await axios.get(
        `https://sjr.widerstand.digital/organisations/5e71edfea9fda91dbbe6f35b`
      )
    ).data;

    this.microsite = (
      await axios.get(
        `https://sjr.widerstand.digital/microsites/${this.organisation.microsite}`
      )
    ).data;

    this.sjr = (
      await axios.get(
        `https://sjr.widerstand.digital/organisations/64e8a9d772a2f36d029f212c`
      )
    ).data;

    this.sjrMicrosite = (
      await axios.get(
        `https://sjr.widerstand.digital/microsites/${this.sjr.microsite}`
      )
    ).data;
    this.partners = this.microsite.partners || [];
    this.team = this.microsite.team || [];
    this.projects = (await axios.get("/jsons/projects.json")).data;
    this.playlist = (await axios.get("/jsons/spotify.json")).data.playlist;
    this.links = (await axios.get("/jsons/links.json")).data;

    await this.$nextTick();

    this.showPage = true;

    this.setBgImage(this.$route.name);

    document.getElementById("first-email-signup-formmce-EMAIL").placeholder =
      "Deine Email-Adresse";
    document.getElementById(
      "first-email-signup-formmc-embedded-subscribe"
    ).innerText = "Anmelden";
  },
  computed: {
    binding() {
      const binding = {};
      if (this.$vuetify.breakpoint.smAndDown) binding.column = true;
      return binding;
    },
  },
};
</script>

<style>
@media only screen and (min-width: 1000px) {
  .responsive-small {
    display: none;
  }
}
@media only screen and (max-width: 999px) {
  .responsive-large {
    display: none;
  }
}

.subscribe-container {
  width: 100%;
  border-radius: 0px;
  opacity: 1;
  background-color: #212121;
  border: none;
  border-top: 1px solid #666;
  box-shadow: none;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-container__title {
  color: #fff;
  font-size: 18px;
}

.subscribe-container__subtitle {
  color: #ccc;
  margin-bottom: 30px;
}

a {
  text-decoration: none !important;
}

.v-text-field__details {
  padding-left: 0px !important;
  padding-top: 5px !important;
}

.preview-container {
  position: absolute;
  display: block;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.preview-container:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.preview-title {
  display: block;
  position: absolute;
  bottom: 0px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
}

.partner-container {
  display: block;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}

.partner-container.big {
  height: 100px;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.bg {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease-in-out;
  opacity: 0.5;
  filter: brightness(0.5);
}

.main-btn {
  padding: 8px;
}

.header-logo {
  margin-left: 0px !important;
  z-index: 1;
}

.header-logo img {
  height: 130px;
}

.clickable,
.clickable * {
  cursor: pointer !important;
}

.v-btn {
  text-transform: none;
}

.body-1 {
  font-size: 16px !important;
  letter-spacing: 0.7px;
}

.v-overlay--active:before {
  opacity: 0.95 !important;
}

.app {
  overflow-x: hidden;
}

.toolbar-odd {
  display: block;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 1;
}

.odd:before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% + 50px);
  height: 50px;
  bottom: -25px;
  left: -25px;
  transform: rotate(3deg);
  background-color: #424242;
}

.triangle {
  fill: #212121;
  stroke: nostroke;
  stroke-width: 0;
}

.odd-footer {
  z-index: 1;
  margin-bottom: -7px;
}

.footer {
  background-color: #212121;
  z-index: 1;
}

.footer-item {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 985px;
  }
}

@media only screen and (max-width: 700px) {
  .display-4 {
    font-size: 54px !important;
  }

  .display-3 {
    font-size: 40px !important;
  }
}

.team-member {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
