var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"display-4":"","font-weight-thin":"","my-5":"","pt-5":""}},[_vm._v(" .neues ")])],1),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.news),function(newsEntry,i){return _c('v-flex',{key:i,attrs:{"id":'id' + i,"xs12":"","my-5":""}},[_c('v-card',[(newsEntry.pics.length > 0)?_c('v-carousel',{staticClass:"flat-carousel",attrs:{"height":"auto","cycle":"","hide-delimiter-background":newsEntry.pics.length === 1,"hide-delimiters":newsEntry.pics.length === 1,"hide-controls":newsEntry.pics.length === 1}},_vm._l((newsEntry.pics),function(pic,i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"aspect-ratio":16 / 9,"src":'https://sjr-bucket.s3.eu-central-1.amazonaws.com/' + pic.name}})],1)}),1):_vm._e(),_c('v-card-title',{class:{
            'display-1': true,
            'font-weight-light': true,
            'mt-4': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(_vm._s(newsEntry.title))]),_c('v-card-text',{class:{
            'body-1': true,
            'px-0': _vm.$vuetify.breakpoint.xsAndDown,
            'px-5': _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('div',{staticClass:"font-weight-light font-italic mb-4"},[_vm._v(" Verfasst am "+_vm._s(_vm.$moment(newsEntry.updatedAt).format("DD.MM.YY, HH:mm"))+" Uhr ")]),_c('div',{domProps:{"innerHTML":_vm._s(newsEntry.info)}}),(newsEntry.links && newsEntry.links.length > 0)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"font-weight-light font-italic mt-5 mb-2"},[_vm._v("Links:")]),_vm._l((newsEntry.links),function(link,i){return _c('div',{key:i},[_c('a',{attrs:{"href":link.url,"target":"_blank"}},[_vm._v(_vm._s(link.text || link.url))])])})],2):_vm._e()])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }