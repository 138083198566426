<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .neues </v-flex>
    </v-layout>

    <!-- news -->

    <v-layout column>
      <v-flex v-for="(newsEntry, i) in news" :key="i" :id="'id' + i" xs12 my-5>
        <v-card>
          <v-carousel
            class="flat-carousel"
            height="auto"
            v-if="newsEntry.pics.length > 0"
            cycle
            :hide-delimiter-background="newsEntry.pics.length === 1"
            :hide-delimiters="newsEntry.pics.length === 1"
            :hide-controls="newsEntry.pics.length === 1"
          >
            <v-carousel-item v-for="(pic, i) in newsEntry.pics" :key="i">
              <v-img
                :aspect-ratio="16 / 9"
                :src="
                  'https://sjr-bucket.s3.eu-central-1.amazonaws.com/' + pic.name
                "
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >{{ newsEntry.title }}</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div class="font-weight-light font-italic mb-4">
              Verfasst am
              {{ $moment(newsEntry.updatedAt).format("DD.MM.YY, HH:mm") }}
              Uhr
            </div>
            <div v-html="newsEntry.info"></div>
            <div
              class="mb-4"
              v-if="newsEntry.links && newsEntry.links.length > 0"
            >
              <div class="font-weight-light font-italic mt-5 mb-2">Links:</div>
              <div v-for="(link, i) in newsEntry.links" :key="i">
                <a :href="link.url" target="_blank">{{
                  link.text || link.url
                }}</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["news"],
  data() {
    return {};
  },
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
};
</script>

<style scoped></style>
