import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import News from "./views/News.vue";
import Projects from "./views/Projects.vue";
import Project from "./views/Project.vue";
import Events from "./views/Events.vue";
import About from "./views/About.vue";
import Partners from "./views/Partners.vue";
import Contact from "./views/Contact.vue";
import Links from "./views/Links.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/neues/:id?",
      name: "neues",
      component: News,
    },
    {
      path: "/projekte",
      name: "projekte",
      component: Projects,
    },
    {
      path: "/projekte/:id",
      name: "projekt",
      component: Project,
    },
    {
      path: "/termine/:id?",
      name: "termine",
      component: Events,
    },
    {
      path: "/ueber-uns/:id?",
      name: "ueber-uns",
      component: About,
    },
    {
      path: "/partner/:id?",
      name: "partner",
      component: Partners,
    },
    {
      path: "/kontakt/:id?",
      name: "kontakt",
      component: Contact,
    },
    {
      path: "/links",
      name: "links",
      component: Links,
    },
  ],
});
